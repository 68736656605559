






























































































import Vue from "vue";
import axios from "axios";

const states = {
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  failed: "failed",
};

export default Vue.extend({
  name: "UpdatePassword",
  data: () => ({
    newPassword: {
      newPassword: "",
      repeatNewPassword: "",
    },
    isFormValid: false,
    state: "idle",
    error: undefined,
    detail: [],
    states,
    token: "",
    headers: "Content-Type=application/x-www-form-urlencoded",
  }),
  mounted() {
    this.token = sessionStorage.getItem("jwt") || "{}";
  },
  methods: {
    ResetForm() {
      this.newPassword = { newPassword: "", repeatNewPassword: "" };
      (
        this.$refs.form as Vue & { resetValidation: () => void }
      ).resetValidation();
    },
    UpdatePassword() {
      this.state = "loading";
      this.error = undefined;
      axios
        .put(
          `${this.$store.state.APIurl}/user/update`,
          {
            password: `${this.newPassword.newPassword}`,
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((_) => {
          this.state = "loaded";
        })
        .catch((error) => {
          this.detail = error.response;
          this.state = "failed";
          this.error = error;
        });
      this.ResetForm();
    },
  },
});
